/**
 * @generated SignedSource<<a7c9e602d2fd752859d03de3b9b8e5a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PathwaySequenceDashboardBlockQuery$variables = {
  id: string;
};
export type PathwaySequenceDashboardBlockQuery$data = {
  readonly node: {
    readonly __typename: "Product";
    readonly id: string;
    readonly viewerHasCompleted: boolean;
    readonly childPathwayGroups: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly title: string;
          readonly viewerMembership: {
            readonly completedAt: string | null;
          } | null;
          readonly products: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly " $fragmentSpreads": FragmentRefs<"PathwaySequenceProductFragment">;
              };
            }>;
          };
        };
      }>;
    };
    readonly hasCertificateTemplate: boolean;
    readonly viewerCertificates: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly mediaUrl: string;
        };
      }>;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type PathwaySequenceDashboardBlockQuery = {
  variables: PathwaySequenceDashboardBlockQuery$variables;
  response: PathwaySequenceDashboardBlockQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerHasCompleted",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completedAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "releaseOption",
      "value": "on_product_completion"
    }
  ],
  "kind": "ScalarField",
  "name": "hasCertificateTemplate",
  "storageKey": "hasCertificateTemplate(releaseOption:\"on_product_completion\")"
},
v8 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mediaUrl",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PathwaySequenceDashboardBlockQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PathwayGroupNodeConnection",
                "kind": "LinkedField",
                "name": "childPathwayGroups",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PathwayGroupNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PathwayGroup",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PathwayGroupMembership",
                            "kind": "LinkedField",
                            "name": "viewerMembership",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductNodeConnection",
                            "kind": "LinkedField",
                            "name": "products",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductNodeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Product",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "PathwaySequenceProductFragment"
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "UserCertificateNodeConnection",
                "kind": "LinkedField",
                "name": "viewerCertificates",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserCertificateNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserCertificate",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "viewerCertificates(first:1)"
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PathwaySequenceDashboardBlockQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PathwayGroupNodeConnection",
                "kind": "LinkedField",
                "name": "childPathwayGroups",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PathwayGroupNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PathwayGroup",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PathwayGroupMembership",
                            "kind": "LinkedField",
                            "name": "viewerMembership",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductNodeConnection",
                            "kind": "LinkedField",
                            "name": "products",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductNodeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Product",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "type",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "name",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "slug",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "startsAt",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "viewerCompletedAt",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "isMembersAppVisibleToAll",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": [
                                          {
                                            "kind": "Literal",
                                            "name": "first",
                                            "value": 3
                                          },
                                          {
                                            "kind": "Literal",
                                            "name": "roles",
                                            "value": [
                                              "member"
                                            ]
                                          }
                                        ],
                                        "concreteType": "ProductMembershipNodeConnection",
                                        "kind": "LinkedField",
                                        "name": "productMemberships",
                                        "plural": false,
                                        "selections": [
                                          (v10/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ProductMembershipNodeEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ProductMembership",
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                  (v3/*: any*/),
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "User",
                                                    "kind": "LinkedField",
                                                    "name": "member",
                                                    "plural": false,
                                                    "selections": [
                                                      (v3/*: any*/),
                                                      {
                                                        "alias": "first_name",
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "firstName",
                                                        "storageKey": null
                                                      },
                                                      {
                                                        "alias": "last_name",
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "lastName",
                                                        "storageKey": null
                                                      },
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "avatar",
                                                        "storageKey": null
                                                      }
                                                    ],
                                                    "storageKey": null
                                                  }
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": "productMemberships(first:3,roles:[\"member\"])"
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Badge",
                                        "kind": "LinkedField",
                                        "name": "badge",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "kind",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "color",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "icon",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "emoji",
                                            "storageKey": null
                                          },
                                          (v9/*: any*/),
                                          (v3/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ProductMembership",
                                        "kind": "LinkedField",
                                        "name": "viewerMembership",
                                        "plural": false,
                                        "selections": [
                                          (v3/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ContentCompletionNodeConnection",
                                            "kind": "LinkedField",
                                            "name": "curriculumModuleCompletions",
                                            "plural": false,
                                            "selections": [
                                              (v10/*: any*/)
                                            ],
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Product",
                                            "kind": "LinkedField",
                                            "name": "product",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Curriculum",
                                                "kind": "LinkedField",
                                                "name": "curriculum",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ContentUsageNodeConnection",
                                                    "kind": "LinkedField",
                                                    "name": "modules",
                                                    "plural": false,
                                                    "selections": [
                                                      (v10/*: any*/),
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "ContentUsageNodeEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                          {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "ContentUsage",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                              (v3/*: any*/),
                                                              {
                                                                "alias": null,
                                                                "args": null,
                                                                "kind": "ScalarField",
                                                                "name": "releasedAt",
                                                                "storageKey": null
                                                              }
                                                            ],
                                                            "storageKey": null
                                                          }
                                                        ],
                                                        "storageKey": null
                                                      }
                                                    ],
                                                    "storageKey": null
                                                  },
                                                  (v3/*: any*/)
                                                ],
                                                "storageKey": null
                                              },
                                              (v3/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "UserCertificateNodeConnection",
                "kind": "LinkedField",
                "name": "viewerCertificates",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserCertificateNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserCertificate",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "viewerCertificates(first:1)"
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7ae25cc5c9ed454b503d3258fb64f2f9",
    "id": null,
    "metadata": {},
    "name": "PathwaySequenceDashboardBlockQuery",
    "operationKind": "query",
    "text": "query PathwaySequenceDashboardBlockQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Product {\n      id\n      viewerHasCompleted\n      childPathwayGroups {\n        edges {\n          node {\n            id\n            title\n            viewerMembership {\n              completedAt\n              id\n            }\n            products {\n              edges {\n                node {\n                  id\n                  ...PathwaySequenceProductFragment\n                }\n              }\n            }\n          }\n        }\n      }\n      hasCertificateTemplate(releaseOption: on_product_completion)\n      viewerCertificates(first: 1) {\n        edges {\n          node {\n            mediaUrl\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment BadgeFragment on Badge {\n  kind\n  color\n  icon\n  emoji\n  mediaUrl\n}\n\nfragment MemberCurriculumProgressBarFragment on ProductMembership {\n  id\n  curriculumModuleCompletions {\n    totalCount\n  }\n  product {\n    curriculum {\n      modules {\n        totalCount\n        edges {\n          node {\n            id\n            releasedAt\n          }\n        }\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment PathwaySequenceProductFragment on Product {\n  id\n  type\n  name\n  slug\n  startsAt\n  viewerCompletedAt\n  isMembersAppVisibleToAll\n  productMemberships(roles: [member], first: 3) {\n    totalCount\n    edges {\n      node {\n        id\n        member {\n          id\n          first_name: firstName\n          last_name: lastName\n          avatar\n        }\n      }\n    }\n  }\n  badge {\n    ...BadgeFragment\n    id\n  }\n  viewerMembership {\n    id\n    ...MemberCurriculumProgressBarFragment\n  }\n}\n"
  }
};
})();

(node as any).hash = "542b7423204cf38d9affb3b6f62eac0c";

export default node;
