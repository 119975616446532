/**
 * @generated SignedSource<<18ebdfb698d55701b308df84064687df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DashboardBlockCurriculumView = "card" | "list" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CurriculumDashboardBlockFragment$data = {
  readonly curriculumView: DashboardBlockCurriculumView;
  readonly " $fragmentSpreads": FragmentRefs<"CurriculumDashboardBlockCardViewFragment" | "CurriculumDashboardBlockListViewFragment">;
  readonly " $fragmentType": "CurriculumDashboardBlockFragment";
};
export type CurriculumDashboardBlockFragment$key = {
  readonly " $data"?: CurriculumDashboardBlockFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CurriculumDashboardBlockFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CurriculumDashboardBlockFragment",
  "selections": [
    {
      "alias": "curriculumView",
      "args": null,
      "kind": "ScalarField",
      "name": "view",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurriculumDashboardBlockCardViewFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurriculumDashboardBlockListViewFragment"
    }
  ],
  "type": "CurriculumDashboardBlock",
  "abstractKey": null
};

(node as any).hash = "583a3bb7c79863443696ae494ca01b33";

export default node;
